import React from 'react';
import { useInView } from '@react-spring/web';
import './Publication.css';

function Publication({ title, authors, publication, year, pages, description }) {
    const [ref, inView] = useInView({
        threshold: 0.1,
        triggerOnce: true,
    });

    return (
        <div ref={ref} className={`publication-container ${inView ? 'visible' : ''}`}>
            <h1 className="publication-title">{title}</h1>
            <h2 className="publication-authors">{authors}</h2>
            <h3 className="publication-info">{publication}, {year}</h3>
            {description && <p className="publication-description">{description}</p>}
            {pages && <p className="publication-pages">Pages: {pages}</p>}
        </div>
    );
}

export default Publication;
