import React, { useState } from 'react';
import './Chatbot.css';

const faqData = {
  'education': 'I am pursuing my MSc in Data Science at Shahid Beheshti University (GPA: 19.18/20) and hold a Bachelor\'s degree in Statistics (GPA: 17.52/20, ranked 3rd in class). I was directly admitted to the Master\'s program without entrance examination based on my academic performance.',
  'skills': 'My core technical skills include:\n• Python for Scientific Computing (95%)\n• Deep Learning with PyTorch & TensorFlow (90%)\n• Natural Language Processing with SPACY & NLTK (90%)\n• Statistical Analysis with R & statsmodels (90%)\n• Big Data Processing with PySpark (85%)\n• Data Science with Pandas, NumPy, Scikit-learn (95%)\n• Machine Learning & Statistical Modeling (90%)',
  'research': 'My Master\'s thesis focuses on "Reliable Anti-Cancer Drug Sensitivity Prediction and Prioritization," using conformal prediction frameworks to enhance drug sensitivity predictions in cancer treatment.',
  'experience': 'Selected Courses and Grades:\n• Machine Learning (20/20)\n• Exploratory Data Analysis (19.4/20)\n• Neural Networks (17.75/20)\n• Statistical Computing (19/20)\n• Multivariate Statistical Analysis (19/20)\n• Nonparametric Methods (19/20)\n• Regression Analysis (19.15/20)\n• Stochastic Processes (19.8/20)\n• Statistical Methods (19.7/20)\n• Probability and Mathematical Statistics (18.8/20)',
  'projects': 'I work on various data science projects including:\n• Credit Card Fraud Detection using anomaly detection\n• World Happiness Report analysis\n• Click-Through Rate Prediction\n• NLP projects including Harry Potter Books Analysis and Persian Text Emotion Detection\n• Real Estate Price Prediction and Customer Segmentation',
  'contact': 'You can reach me at mhparia242@gmail.com or connect with me on LinkedIn: linkedin.com/in/paria-mohammadi-b5b499208'
};

function Chatbot() {
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const lowercaseInput = input.toLowerCase();
    const answer = Object.keys(faqData).find(key => lowercaseInput.includes(key));
    const response = faqData[answer] || "I'm sorry, I don't have information about that. Please try asking about my education, skills, experience, projects, or contact information.";
    
    setMessages([...messages, { type: 'user', text: input }, { type: 'bot', text: response }]);
    setInput('');
  };

  return (
    <div className={`chatbot ${isOpen ? 'open' : ''}`}>
      <button className="chatbot-toggle" onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? 'Close' : 'Chat with me'}
      </button>
      {isOpen && (
        <div className="chatbot-container">
          <div className="chatbot-messages">
            {messages.map((message, index) => (
              <div key={index} className={`message ${message.type}`}>
                {message.text}
              </div>
            ))}
          </div>
          <form onSubmit={handleSubmit}>
            <input 
              value={input} 
              onChange={(e) => setInput(e.target.value)} 
              placeholder="Ask me about my resume" 
            />
            <button type="submit">Send</button>
          </form>
        </div>
      )}
    </div>
  );
}

export default Chatbot;