import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Contact from "./routes/contact";
import {NavLink} from "react-router-dom";
import {Link} from "react-router-dom";
import  { useState,Suspense } from "react";
import{
    createBrowserRouter,
    RouterProvider,
    Route
} from "react-router-dom";
import App from "./App";
import React from "react";
import './App.css';

const router1 = createBrowserRouter(
    [{
        path: "contact/",
        element: <Contact />,
    },
    ]);

function Navboot({ setCurrentTab }) {
    return (
        <div className="nav-container">
            <Nav fill="true" variant="pills" defaultActiveKey="/home">
                <Nav.Link as={Link} to="/" onClick={() => setCurrentTab("About Me")}><i className="fas fa-user"></i> Home</Nav.Link>
                <Nav.Link as={Link} to="/courses" onClick={() => setCurrentTab("Selected Courses")}><i className="fas fa-briefcase"></i> Selected Courses</Nav.Link>
                <Nav.Link as={Link} to="/education" onClick={() => setCurrentTab("Education")}><i className="fas fa-graduation-cap"></i> Education</Nav.Link>
                <Nav.Link as={Link} to="/skills" onClick={() => setCurrentTab("Skills")}><i className="fas fa-cogs"></i> Skills</Nav.Link>
                <Nav.Link as={Link} to="/certificates" onClick={() => setCurrentTab("Certificates")}><i className="fas fa-certificate"></i> Certificates</Nav.Link>
                <Nav.Link as={Link} to="/honors" onClick={() => setCurrentTab("Honors")}><i className="fas fa-award"></i> Honors</Nav.Link>
                <Nav.Link as={Link} to="/contact" onClick={() => setCurrentTab("Contact")}><i className="fas fa-envelope"></i> Contact</Nav.Link>
                <Nav.Link as={Link} to="/projects" onClick={() => setCurrentTab("Projects")}><i className="fas fa-code"></i> Projects</Nav.Link>
            </Nav>
        </div>
    );
}
export default Navboot;