import logo from './logo.svg';
import './App.css';
import Navboot from "./NavBoot";
import {Routes, Route, Link, useLocation} from "react-router-dom";
import Backg from "./components/backg";
import React from "react";
import Contact from "./routes/contact";
import {Parallax, ParallaxLayer} from '@react-spring/parallax';
import {useState, Suspense, useEffect} from "react";

import leaf from './black-leaf.jpg';
import color from './colourful.jpg';
import Nav from "react-bootstrap/Nav";
import Skills1 from "./components/Skills1";
import Employment from "./components/Employment";
import Title from "./components/Title";

import checklist from "./icons/checklist.gif";
import hacker from "./icons/hacker.gif";
import message from "./icons/message.gif";
import mortarboard from "./icons/mortarboard.gif";
import diploma from "./icons/diploma.gif";
import certificate from "./icons/certificate.gif";
import ParticleImage from "./components/ParticleImage";
import AIPhotoEffect from "./components/AIPhotoEffect";
import {TabPane} from "react-bootstrap";
import Publications from "./components/Publications";
import Publications_List from "./components/Publications_List";
import CitationsChart from "./components/CitationsChart";
import LoadingScreen from './components/LoadingScreen';
import ProjectBlock from "./components/ProjectBlock";
import imageSrc from "./components/image"; // Import the image from image.js
import quantumimg from "./components/quantumimg"; // Import the quantum image
import blockchainimg from "./components/blockchain"; // Import the blockchain image
import AIimg from "./components/AIimg"; // Import the AI image
import powerimg from './components/powerimg';
import huaweiIMG from './components/huaweiIMG';
import Chatbot from "./components/Chatbot"; // Import the Chatbot component
const Name = 'Parya Mohammadi';
const Message = 'Welcome to my website';
const Message2 = 'Blockchain Developer';
const Message3 = 'DevOps Engineer';
const Message4 = 'Machine Learning Engineer';

function App() {
    const [currentTab, setCurrentTab] = React.useState("About Me");
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate loading time
        setTimeout(() => {
            setIsLoading(false);
        }, 3000); // 3 seconds loading time, adjust as needed
    }, []);

    React.useEffect(() => {
        const path = location.pathname.slice(1);
        if (path) {
            if (path === "courses") {
                setCurrentTab("Selected Courses");
            } else {
                setCurrentTab(path.charAt(0).toUpperCase() + path.slice(1));
            }
        } else {
            setCurrentTab("About Me");
        }
    }, [location]);

    if (isLoading) {
        return <LoadingScreen />;
    }

    return (
        <div className="App">
            <div className="full-screen-bg" style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: -1 }}>
                <AIPhotoEffect />
            </div>
            <header className="App-header" style={{ backgroundColor: 'transparent', position: 'relative', zIndex: 1 }}>
                <Parallax pages={4} style={{ backgroundColor: 'transparent' }}>
                    <ParallaxLayer offset={0} speed={0} factor={3} style={{
                        backgroundColor: "rgba(255,255,255,0)",
                        backgroundSize: 'cover',
                        zIndex: 100
                    }}>
                        <Navboot setCurrentTab={setCurrentTab} />
                        {currentTab === "About Me" && (
                            <div>
                                <Title SectionTitle={"About Me"}/>
                                <div className="about-me-content">
                                    <div className="project-grid">
                                        <ProjectBlock 
                                            title="About Me"
                                            description="As a dedicated researcher with a strong academic foundation in data science and statistics, I am currently pursuing my MASc in Data Science (GPA: 19.18) at Shahid Beheshti University. My research focuses on applying machine learning and statistical methods to healthcare, particularly in predictive modeling and personalized medicine. With a Bachelor's degree in Statistics (GPA: 17.52, ranked 3rd in my class), I combine strong theoretical knowledge with practical applications in healthcare analytics."
                                            technologies={["Data Science", "Statistics", "Healthcare Analytics"]}
                                            imageSrc={powerimg}
                                            isAboutMe={true}
                                        />
                                        <ProjectBlock 
                                            title="Healthcare Analytics Research"
                                            description="My Master's thesis focuses on 'Reliable Anti-Cancer Drug Sensitivity Prediction and Prioritization,' exploring conformal prediction frameworks to enhance the reliability and accuracy of drug sensitivity predictions."
                                            technologies={["Machine Learning", "Statistical Analysis", "Predictive Modeling"]}
                                            imageSrc={AIimg}
                                        />
                                        <ProjectBlock 
                                            title="Statistical Computing"
                                            description="Experienced in applying advanced statistical methods and machine learning techniques to healthcare data, with a focus on developing reliable prediction models for personalized medicine."
                                            technologies={["R", "Python", "Statistical Analysis"]}
                                            imageSrc={quantumimg}
                                        />
                                        <ProjectBlock 
                                            title="Data Science Applications"
                                            description="Specialized in computational biology and healthcare analytics, combining statistical expertise with modern data science tools to solve complex medical research challenges."
                                            technologies={["Data Analysis", "Bioinformatics", "Healthcare"]}
                                            imageSrc={blockchainimg}
                                        />
                                        {/* Add more ProjectBlock components as needed */}
                                    </div>
                                </div>
                                {/* Remove the Employment component if you no longer need it */}
                            </div>
                        )}
                        {currentTab === "Selected Courses" && (
                            <div>
                                <Title SectionTitle={"Selected Courses"}/>
                                <Employment company={"Shahid Beheshti University"} date={"2023-2024"}
                                            title={"Machine Learning"}
                                            description={"Score: 20/20. Covered supervised and unsupervised learning techniques, including decision trees, SVMs, and clustering, with applications in marketing and healthcare."}/>
                                <Employment company={"Shahid Beheshti University"} date={"2023-2024"}
                                            title={"Exploratory Data Analysis"}
                                            description={"Score: 19.4/20. Focused on techniques for data summarization and visualization to identify patterns, trends, and anomalies in complex datasets."}/>
                                <Employment company={"Shahid Beheshti University"} date={"2023-2024"}
                                            title={"Neural Networks"}
                                            description={"Score: 17.75/20. Studied artificial neural networks including feedforward, convolutional, and recurrent architectures for tasks like image recognition and time series prediction."}/>
                                <Employment company={"Shahid Beheshti University"} date={"2022-2023"}
                                            title={"Statistical Computing"}
                                            description={"Score: 19/20. Implemented statistical methods using Python and R, focusing on efficient data processing and visualization techniques."}/>
                                <Employment company={"Shahid Beheshti University"} date={"2022-2023"}
                                            title={"Multivariate Statistical Analysis"}
                                            description={"Score: 19/20. Mastered advanced techniques including PCA, factor analysis, MANOVA, and cluster analysis for high-dimensional data."}/>
                                <Employment company={"Shahid Beheshti University"} date={"2022-2023"}
                                            title={"Nonparametric Methods"}
                                            description={"Score: 19/20. Studied distribution-free methods including rank-based tests and bootstrapping for analyzing non-normal datasets."}/>
                                <Employment company={"Shahid Beheshti University"} date={"2021-2022"}
                                            title={"Regression Analysis"}
                                            description={"Score: 19.15/20. Covered linear regression, logistic regression, ridge and lasso regression, and polynomial regression for predictive modeling."}/>
                                <Employment company={"Shahid Beheshti University"} date={"2020-2021"}
                                            title={"Stochastic Processes"}
                                            description={"Score: 19.8/20. Explored time-dependent random processes including Markov chains, Poisson processes, and renewal processes."}/>
                                <Employment company={"Shahid Beheshti University"} date={"2020-2021"}
                                            title={"Statistical Methods"}
                                            description={"Score: 19.7/20. Mastered practical tools for data analysis including hypothesis testing, confidence intervals, ANOVA, and chi-square tests."}/>
                                <Employment company={"Shahid Beheshti University"} date={"2020-2021"}
                                            title={"Probability and Mathematical Statistics"}
                                            description={"Score: 18.8/20. Studied advanced probability theory, distributions, and statistical inference methods essential for data science."}/>
                            </div>
                        )}
                        {currentTab === "Education" && (
                            <div>
                                <Title SectionTitle={"Education"}/>
                                <Employment 
                                    company={"Shahid Beheshti University"} 
                                    date={"2022 - 2024"} 
                                    title={"Master of Science in Data Science"}
                                    description={"GPA: 19.18/20\nFocus: Statistical Learning and Machine Learning\nThesis: 'Reliable Anti-Cancer Drug Sensitivity Prediction and Prioritization'\nResearch: Developing conformal prediction frameworks to enhance the reliability and accuracy of drug sensitivity predictions in cancer treatment."}
                                />
                                <Employment 
                                    company={"Shahid Beheshti University"} 
                                    date={"2018 - 2022"} 
                                    title={"Bachelor of Science in Statistics"}
                                    description={"GPA: 17.52/20\nRanked 3rd in graduating class\nFocus: Statistical Analysis and Data Science\nCompleted comprehensive coursework in probability theory, statistical inference, and computational methods."}
                                />
                            </div>
                        )}
                        {currentTab === "Skills" && (
                            <div>
                                <Title SectionTitle={"Technical Skills"}/>
                                <Skills1 skills={"Python for Scientific Computing"} percentage={95}/>
                                <Skills1 skills={"Deep Learning (PyTorch, TensorFlow)"} percentage={90}/>
                                <Skills1 skills={"Natural Language Processing (SPACY, NLTK)"} percentage={90}/>
                                <Skills1 skills={"Statistical Analysis (statsmodels, R)"} percentage={90}/>
                                <Skills1 skills={"Big Data Processing (PySpark)"} percentage={85}/>
                                <Skills1 skills={"Data Science (Pandas, NumPy, Scikit-learn)"} percentage={95}/>
                                <Skills1 skills={"Machine Learning & Statistical Modeling"} percentage={90}/>
                            </div>
                        )}
                        {currentTab === "Certificates" && (
                            <div>
                                <Title SectionTitle={"Certificates"}/>
                                <Employment 
                                    company={"Dayche"} 
                                    date={"September 2021 - October 2022"} 
                                    title={"Professional Data Science Program"}
                                    description={
                                        "344-hour comprehensive data science training covering:\n\n" +
                                        "• Statistical Analysis & Data Mining: Advanced statistical methods, data mining processes, and problem-solving methodologies\n\n" +
                                        "• Programming & Version Control: Python programming, GIT, SQL databases, API integration, and web scraping\n\n" +
                                        "• Data Science Libraries: Proficiency in Pandas, NumPy, Scikit-learn for data preprocessing, visualization, and modeling\n\n" +
                                        "• Machine Learning & Deep Learning: Implementation of ML algorithms, NLP, computer vision, and deep learning concepts\n\n" +
                                        "• Big Data Technologies: Linux, Docker, MongoDB, Hive distributed data warehouse, Spark SQL, and ML in PySpark"
                                    }
                                />
                            </div>
                        )}
                        {currentTab === "Honors" && (
                            <div>
                                <Title SectionTitle={"Honors"}/>
                                <Employment 
                                    company={"Shahid Beheshti University"} 
                                    date={"2022"} 
                                    title={"Direct Admission to Master's Program"}
                                    description={"Admitted directly to the Master's program in Data Science without entrance examination based on outstanding academic performance in Bachelor's degree."}
                                />
                                <Employment 
                                    company={"Shahid Beheshti University"} 
                                    date={"2022"} 
                                    title={"Top Student Achievement"}
                                    description={"Ranked 3rd among Bachelor of Science in Statistics graduates with a GPA of 17.52/20."}
                                />
                            </div>
                        )}
                        {currentTab === "Contact" && (
                            <div>
                                <Title SectionTitle={"Contact"}/>
                                <Employment company={"Email"} link={"mhparia243@gmail.com"}></Employment>
                                <Employment company={"Phone"} date={"+1-4374237704"}></Employment>
                                <Employment company={"LinkedIn"}
                                            link={"https://www.linkedin.com/in/paria-mohammadi-b5b499208/"}></Employment>
                            </div>
                        )}
                        {currentTab === "Projects" && (
                            <div>
                                <Title SectionTitle={"Projects"}/>
                                <Publications_List/>
                            </div>
                        )}
                    </ParallaxLayer>
                </Parallax>
            </header>
            {/* Add the Chatbot component outside of any specific tab */}
            <Chatbot />
        </div>
    )
}

export default App;