import React from 'react';
import Publication from './Publications';

const Publications_List = () => {
    const publications = [
        {
            title: "Credit Card Fraud Detection",
            authors: "Machine Learning, Data Analysis",
            publication: "Python (numpy, pandas, scikit-learn)",
            description: "Implemented anomaly detection algorithms (OneClassSVM, IsolationForest, LOF, DBSCAN) to identify fraudulent transactions in a dataset of 284,807 entries.",
            year: 2023
        },
        {
            title: "World Happiness Report",
            authors: "Statistical Analysis, Data Visualization",
            publication: "R (ggplot2, dplyr)",
            description: "Analyzed global happiness levels across 155 countries using clustering and multiple linear regression to identify factors influencing happiness scores.",
            year: 2023
        },
        {
            title: "Click-Through Rate Prediction",
            authors: "Machine Learning, Data Science",
            publication: "Python (scikit-learn, pandas)",
            description: "Developed logistic regression model to predict ad click-through rates using demographic and behavioral data from 10,000 users.",
            year: 2023
        },
        {
            title: "Harry Potter Books Analysis",
            authors: "NLP, Clustering",
            publication: "Python (nltk, gensim, transformers)",
            description: "Applied NER and Word2Vec for character analysis and clustering into Hogwarts houses using textual data from the series.",
            year: 2022
        },
        {
            title: "Emotion Detection in Persian Text",
            authors: "NLP, Classification",
            publication: "Python (hazm, scikit-learn, xgboost)",
            description: "Built multi-model classifier for emotion detection in Persian text across 5 emotional categories using 4,923 training samples.",
            year: 2022
        },
        {
            title: "Spaceship Titanic",
            authors: "Classification, Feature Engineering",
            publication: "Python (scikit-learn, pandas)",
            description: "Developed logistic regression model achieving 77% accuracy in predicting passenger transport status using demographic and cabin data.",
            year: 2022
        },
        {
            title: "Real Estate Price Prediction",
            authors: "Regression Analysis",
            publication: "Python (scikit-learn, statsmodels)",
            description: "Created linear regression model to predict house prices using features like location, age, and amenities with statistical analysis.",
            year: 2022
        },
        {
            title: "Mall Customer Segmentation",
            authors: "Clustering Analysis",
            publication: "Python (scikit-learn)",
            description: "Applied multiple clustering algorithms (K-Means, DBSCAN) to segment 200 customers based on spending patterns.",
            year: 2021
        },
        {
            title: "Fashion MNIST Classification",
            authors: "Deep Learning",
            publication: "PyTorch",
            description: "Implemented MLP neural network with dropout and regularization techniques for image classification on Fashion MNIST dataset.",
            year: 2021
        },
        {
            title: "Flickr Image Captioning",
            authors: "Computer Vision, NLP",
            publication: "PyTorch, CNN-LSTM",
            description: "Developed image captioning system using encoder-decoder architecture with attention mechanism on Flickr30K dataset.",
            year: 2021
        },
        {
            title: "Agricultural Pests Classification",
            authors: "Computer Vision",
            publication: "PyTorch, ZFNet",
            description: "Built optimized ZFNet architecture for classifying 12 categories of agricultural pests with data augmentation.",
            year: 2021
        }
    ];

    return (
        <div>
            {publications.map((publication, index) => (
                <Publication
                    key={index}
                    title={publication.title}
                    authors={publication.authors}
                    publication={publication.publication}
                    year={publication.year}
                    description={publication.description}
                />
            ))}
        </div>
    );
};

export default Publications_List;
